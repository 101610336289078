import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Footer() {
  const year = new Date()
  return (
    <footer className="footer" id="footer_block">
      <div className="py-5">
        <div className="f-bottom py-5">
          <div className="container text-center">
            <div className="w-90 ma flex jcc aic">
              <p className="text text-left c-premium w-50">
                <br />
                <AnchorLink className="c-premium" href="#root">Con10T Labs</AnchorLink> - &copy;{year.getFullYear()}. All Rights Reserved</p>
              <div id="info" className="w-50 text-right">
                <p className='text c-premium'>Phone: <a href="tel:9677092727">9677092727</a><br /><br />
                <span className='text c-premium'>Headquarters: Chennai, Tamil Nadu</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
