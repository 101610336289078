import React from 'react'

import pic from '../assets/img/content/about.png'

function About() {
  return (
    <div className="section" id="about_block">

      <div className="w-80 ma">
        <h2 className='title c-blue'>About us</h2>
      </div>
      <div className="s-40"></div>
      <div className="s-40"></div>
      <div className="container">
        <div className="flex w-90 ma jcsa aic">
          <div className="w-50 flex jcc aic p-5">
            <img src={pic} alt="pic" />
          </div>
          <div className="w-40">
            <p className="subtitle c-theme text-right">Stay tuned for more! We are a few short months away from launching!</p>
            <p className="text c-middle text-right">A Content aggregation app that seeks to enter the market through comedic content – Memes. The objective of the Cap10 is to be a user-generated content app with a gamification aspect. Not only will users be able to come and enjoy the content on the platform, but they will also be able to compete against each other in battles! The product will be built over many phases so that users can be comfortably introduced to all the features that the app has to offer.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
