import React, { useState, useEffect } from 'react'
import Menu from './Menu'

function Header() {
  const [show, setShow] = useState('')
  const [mobile, setMobile] = useState('')
  const [shadows, setShadows] = useState('')
  const [back, setBack] = useState('transparent')
  const [offset, setOffset] = useState(0)

  const newBack = '#fff'
  const newShadow = '0 1px 3px rgba(0,0,0,.15)'

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.pageYOffset)
      offset > 150 ? setBack(newBack) : setBack(back)
      offset > 150 ? setShadows(newShadow) : setShadows(shadows)
      offset <= 150 ? setBack('') : setBack(newBack)
      offset <= 150 ? setShadows('') : setShadows(newShadow)
    }
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [back, offset, shadows]);

  return (
    <section id="top_block" data-offset={offset} style={{ background: back, shadow: shadows }}>
      <div className="container">
        <div
          className={`hb-burger ${show}`}
          onClick={() => {
            show === 'opened' ? setShow('') : setShow('opened')
            mobile === 'mob-menu' ? setMobile('') : setMobile('mob-menu')
          }}>
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
        </div>
        <Menu mobile={mobile} />
      </div>
    </ section>
  );
}

export default Header
