import React from 'react'
import { FaLinkedin } from "react-icons/fa"

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '../logo.png'

function Menu(props) {
  const blue = '#2191d6'
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <AnchorLink className="hb-logo__link scroll" href="#root">
          <img src={Logo} width={64} alt="logo" style={{ marginTop: 20 }} />
          <h1 className="hb-logo__text">Con10T Labs<br /><span>For Your Success</span></h1>
        </AnchorLink>
      </h1>
      <div className={`hb-menu ${props.mobile}`}>
        <AnchorLink className="hb-menu__link" href="#about_block">About</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#info_block">Mission</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#feature_block">Features</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#team_block">Team</AnchorLink>
        <AnchorLink className="hb-menu__link" href="#contact_block">Support</AnchorLink>
        <a href="https://www.linkedin.com/company/con10tlabs/" className='ml-5 pl-2 pb-1'>
          <FaLinkedin size={28} color={blue} />
        </a>
      </div>
    </div>
  );
}

export default Menu
