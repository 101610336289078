import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Title from './Title'
import pic1 from '../assets/img/team/person001.png'
import pic2 from '../assets/img/team/person002.png'
import pic3 from '../assets/img/team/person003.png'
import pic4 from '../assets/img/team/person004.png'
import pic5 from '../assets/img/team/person005.png'
import pic6 from '../assets/img/team/person006.png'


function Team() {

  const slides = [
    {
      id: 1,
      pic: pic1,
      position: 'International Manager at the Corporate Startup',
      name: 'Lydia Patterson'
    },
    {
      id: 2,
      pic: pic2,
      position: 'Quality Assurance Intern @Con10tLabs | EX- Product and Project Manager @QurosTech | Minors in Data Science',
      name: `Joyce O'Doherty`
    },
    {
      id: 3,
      pic: pic3,
      position: 'Full stack Developer',
      name: 'Zamri Jau'
    },
    {
      id: 4,
      pic: pic4,
      position: 'LSE | IIM-I | Rutgers | CMA / Finance & Strategy Expert',
      name: 'Bailey Mann'
    },
    {
      id: 5,
      pic: pic5,
      position: 'Entrepreneur | Public Speaker | Business Analyst | Fitness Enthusiast | Founder & CEO @ Con10T Labs and Ondru',
      name: 'Wee Mohammed'
    },
    {
      id: 6,
      pic: pic6,
      position: 'Entrepreneur | Co-Founder & CMO - Con10T Labs PVT LTD | Founder - SpicySip',
      name: 'Wei Azman'
    }
  ]

  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: true,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='mx-5 team'>
        <Slider {...settings}>
          {slides.map(screen => (
            <div className="w-30 my-5">
              <div className='shadow-sm bg-lite mx-5 brd-blue r-16 br-two h-60'>
                <div className="bg-white flex jcc aic py-3 r-16">
                  <img
                    className='my-3 p-4'
                    src={screen.pic}
                    width={100}
                    height={100}
                    alt={'pic'}
                  />
                </div>
                <h3 className='subtitle'>{screen.name}</h3>
                <span className='text'>{screen.position}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <div className="section" id="team_block">
      <Title
        type="title c-medium"
        text="The team that creates content for you"
      />
      <div className="container">
        <h2 className="subtitle w-60 ma text-center">We know your business is important to you, which is why we provide the finest quality assurance and control for every writer and translator in our network
        </h2>
        <div className="s-40"></div>
        <div className="w-90 ma"><Gallery /></div>
      </div>
    </div>
  );
}

export default Team
