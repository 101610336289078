import React from 'react'

function Cta() {
  return (
    <div className="section" id="cta_block">
      <div className="flex aic jcc">
        <div className="text-center">
          <div className="s-100"></div>
          <p className="c-white p-5 title w-80 ma" style={{ textTransform: 'none' }}>Use promotional text for your content for instant success!</p>
          <div className="s-20"></div>
          <a className="btn btn--big btn--premium btn--big" href="https://www.linkedin.com/company/con10tlabs/">Start Now</a>
        </div>
      </div>
    </div>
  );
}

export default Cta
