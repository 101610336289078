import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import ac from '../assets/flags/ac.svg'
import bv from '../assets/flags/bv.svg'
import ee from '../assets/flags/ee.svg'
import gs from '../assets/flags/gs.svg'
import kw from '../assets/flags/kw.svg'
import mx from '../assets/flags/mx.svg'
import ru from '../assets/flags/ru.svg'
import to from '../assets/flags/to.svg'
import ad from '../assets/flags/ad.svg'
import bw from '../assets/flags/bw.svg'
import eg from '../assets/flags/eg.svg'
import gt from '../assets/flags/gt.svg'
import ky from '../assets/flags/ky.svg'
import my from '../assets/flags/my.svg'
import rw from '../assets/flags/rw.svg'
import tr from '../assets/flags/tr.svg'
import ae from '../assets/flags/ae.svg'
import by from '../assets/flags/by.svg'
import eh from '../assets/flags/eh.svg'
import gu from '../assets/flags/gu.svg'
import kz from '../assets/flags/kz.svg'
import mz from '../assets/flags/mz.svg'
import sa from '../assets/flags/sa.svg'
import tt from '../assets/flags/tt.svg'
import af from '../assets/flags/af.svg'
import bz from '../assets/flags/bz.svg'
import er from '../assets/flags/er.svg'
import gw from '../assets/flags/gw.svg'
import la from '../assets/flags/la.svg'
import na from '../assets/flags/na.svg'
import sb from '../assets/flags/sb.svg'
import tv from '../assets/flags/tv.svg'
import ag from '../assets/flags/ag.svg'
import ca from '../assets/flags/ca.svg'
import gy from '../assets/flags/gy.svg'
import lb from '../assets/flags/lb.svg'
import nc from '../assets/flags/nc.svg'
import sc from '../assets/flags/sc.svg'
import tw from '../assets/flags/tw.svg'
import ai from '../assets/flags/ai.svg'
import cc from '../assets/flags/cc.svg'
import hk from '../assets/flags/hk.svg'
import lc from '../assets/flags/lc.svg'
import ne from '../assets/flags/ne.svg'
import sd from '../assets/flags/sd.svg'
import tz from '../assets/flags/tz.svg'
import al from '../assets/flags/al.svg'
import cd from '../assets/flags/cd.svg'
import hm from '../assets/flags/hm.svg'
import li from '../assets/flags/li.svg'
import nf from '../assets/flags/nf.svg'
import se from '../assets/flags/se.svg'
import ua from '../assets/flags/ua.svg'
import am from '../assets/flags/am.svg'
import es from '../assets/flags/es.svg'
import hn from '../assets/flags/hn.svg'
import lk from '../assets/flags/lk.svg'
import ng from '../assets/flags/ng.svg'
import sg from '../assets/flags/sg.svg'
import ug from '../assets/flags/ug.svg'
import ao from '../assets/flags/ao.svg'
import cf from '../assets/flags/cf.svg'
import et from '../assets/flags/et.svg'
import hr from '../assets/flags/hr.svg'
import lr from '../assets/flags/lr.svg'
import ni from '../assets/flags/ni.svg'
import sh from '../assets/flags/sh.svg'
import um from '../assets/flags/um.svg'
import aq from '../assets/flags/aq.svg'
import cg from '../assets/flags/cg.svg'
import eu from '../assets/flags/eu.svg'
import ht from '../assets/flags/ht.svg'
import ls from '../assets/flags/ls.svg'
import nl from '../assets/flags/nl.svg'
import si from '../assets/flags/si.svg'
import un from '../assets/flags/un.svg'
import ar from '../assets/flags/ar.svg'
import ch from '../assets/flags/ch.svg'
import fi from '../assets/flags/fi.svg'
import hu from '../assets/flags/hu.svg'
import lt from '../assets/flags/lt.svg'
import no from '../assets/flags/no.svg'
import sj from '../assets/flags/sj.svg'
import us from '../assets/flags/us.svg'
import as from '../assets/flags/as.svg'
import ci from '../assets/flags/ci.svg'
import fj from '../assets/flags/fj.svg'
import ic from '../assets/flags/ic.svg'
import lu from '../assets/flags/lu.svg'
import np from '../assets/flags/np.svg'
import sk from '../assets/flags/sk.svg'
import uy from '../assets/flags/uy.svg'
import at from '../assets/flags/at.svg'
import ck from '../assets/flags/ck.svg'
import fk from '../assets/flags/fk.svg'
import id from '../assets/flags/id.svg'
import lv from '../assets/flags/lv.svg'
import nr from '../assets/flags/nr.svg'
import sl from '../assets/flags/sl.svg'
import uz from '../assets/flags/uz.svg'
import au from '../assets/flags/au.svg'
import cl from '../assets/flags/cl.svg'
import fm from '../assets/flags/fm.svg'
import ie from '../assets/flags/ie.svg'
import ly from '../assets/flags/ly.svg'
import nu from '../assets/flags/nu.svg'
import sm from '../assets/flags/sm.svg'
import va from '../assets/flags/va.svg'
import aw from '../assets/flags/aw.svg'
import cm from '../assets/flags/cm.svg'
import fo from '../assets/flags/fo.svg'
import il from '../assets/flags/il.svg'
import ma from '../assets/flags/ma.svg'
import nz from '../assets/flags/nz.svg'
import sn from '../assets/flags/sn.svg'
import vc from '../assets/flags/vc.svg'
import ax from '../assets/flags/ax.svg'
import cn from '../assets/flags/cn.svg'
import fr from '../assets/flags/fr.svg'
import im from '../assets/flags/im.svg'
import mc from '../assets/flags/mc.svg'
import om from '../assets/flags/om.svg'
import so from '../assets/flags/so.svg'
import ve from '../assets/flags/ve.svg'
import az from '../assets/flags/az.svg'
import co from '../assets/flags/co.svg'
import ga from '../assets/flags/ga.svg'
import iin from '../assets/flags/in.svg'
import md from '../assets/flags/md.svg'
import pa from '../assets/flags/pa.svg'
import sr from '../assets/flags/sr.svg'
import vg from '../assets/flags/vg.svg'
import ba from '../assets/flags/ba.svg'
import cp from '../assets/flags/cp.svg'
import io from '../assets/flags/io.svg'
import me from '../assets/flags/me.svg'
import pe from '../assets/flags/pe.svg'
import ss from '../assets/flags/ss.svg'
import vi from '../assets/flags/vi.svg'
import bb from '../assets/flags/bb.svg'
import cr from '../assets/flags/cr.svg'
import iq from '../assets/flags/iq.svg'
import mf from '../assets/flags/mf.svg'
import pf from '../assets/flags/pf.svg'
import st from '../assets/flags/st.svg'
import vn from '../assets/flags/vn.svg'
import bd from '../assets/flags/bd.svg'
import cu from '../assets/flags/cu.svg'
import ir from '../assets/flags/ir.svg'
import mg from '../assets/flags/mg.svg'
import pg from '../assets/flags/pg.svg'
import sv from '../assets/flags/sv.svg'
import vu from '../assets/flags/vu.svg'
import be from '../assets/flags/be.svg'
import cv from '../assets/flags/cv.svg'
import is from '../assets/flags/is.svg'
import mh from '../assets/flags/mh.svg'
import ph from '../assets/flags/ph.svg'
import sx from '../assets/flags/sx.svg'
import wf from '../assets/flags/wf.svg'
import bf from '../assets/flags/bf.svg'
import cw from '../assets/flags/cw.svg'
import gb from '../assets/flags/gb.svg'
import it from '../assets/flags/it.svg'
import mk from '../assets/flags/mk.svg'
import pk from '../assets/flags/pk.svg'
import sy from '../assets/flags/sy.svg'
import ws from '../assets/flags/ws.svg'
import bg from '../assets/flags/bg.svg'
import cx from '../assets/flags/cx.svg'
import gd from '../assets/flags/gd.svg'
import je from '../assets/flags/je.svg'
import ml from '../assets/flags/ml.svg'
import pl from '../assets/flags/pl.svg'
import sz from '../assets/flags/sz.svg'
import xk from '../assets/flags/xk.svg'
import bh from '../assets/flags/bh.svg'
import cy from '../assets/flags/cy.svg'
import ge from '../assets/flags/ge.svg'
import jm from '../assets/flags/jm.svg'
import mm from '../assets/flags/mm.svg'
import pm from '../assets/flags/pm.svg'
import ta from '../assets/flags/ta.svg'
import xx from '../assets/flags/xx.svg'
import bi from '../assets/flags/bi.svg'
import cz from '../assets/flags/cz.svg'
import gf from '../assets/flags/gf.svg'
import jo from '../assets/flags/jo.svg'
import mn from '../assets/flags/mn.svg'
import pn from '../assets/flags/pn.svg'
import tc from '../assets/flags/tc.svg'
import ye from '../assets/flags/ye.svg'
import bj from '../assets/flags/bj.svg'
import de from '../assets/flags/de.svg'
import gg from '../assets/flags/gg.svg'
import jp from '../assets/flags/jp.svg'
import mo from '../assets/flags/mo.svg'
import pr from '../assets/flags/pr.svg'
import td from '../assets/flags/td.svg'
import yt from '../assets/flags/yt.svg'
import bl from '../assets/flags/bl.svg'
import dg from '../assets/flags/dg.svg'
import gh from '../assets/flags/gh.svg'
import ke from '../assets/flags/ke.svg'
import mp from '../assets/flags/mp.svg'
import ps from '../assets/flags/ps.svg'
import tf from '../assets/flags/tf.svg'
import za from '../assets/flags/za.svg'
import bm from '../assets/flags/bm.svg'
import dj from '../assets/flags/dj.svg'
import gi from '../assets/flags/gi.svg'
import kg from '../assets/flags/kg.svg'
import mq from '../assets/flags/mq.svg'
import pt from '../assets/flags/pt.svg'
import tg from '../assets/flags/tg.svg'
import zm from '../assets/flags/zm.svg'
import bn from '../assets/flags/bn.svg'
import dk from '../assets/flags/dk.svg'
import gl from '../assets/flags/gl.svg'
import kh from '../assets/flags/kh.svg'
import mr from '../assets/flags/mr.svg'
import pw from '../assets/flags/pw.svg'
import th from '../assets/flags/th.svg'
import zw from '../assets/flags/zw.svg'
import bo from '../assets/flags/bo.svg'
import dm from '../assets/flags/dm.svg'
import gm from '../assets/flags/gm.svg'
import ki from '../assets/flags/ki.svg'
import ms from '../assets/flags/ms.svg'
import py from '../assets/flags/py.svg'
import tj from '../assets/flags/tj.svg'
import bq from '../assets/flags/bq.svg'
import ddo from '../assets/flags/do.svg'
import gn from '../assets/flags/gn.svg'
import km from '../assets/flags/km.svg'
import mt from '../assets/flags/mt.svg'
import qa from '../assets/flags/qa.svg'
import tk from '../assets/flags/tk.svg'
import br from '../assets/flags/br.svg'
import dz from '../assets/flags/dz.svg'
import gp from '../assets/flags/gp.svg'
import kn from '../assets/flags/kn.svg'
import mu from '../assets/flags/mu.svg'
import re from '../assets/flags/re.svg'
import tl from '../assets/flags/tl.svg'
import bs from '../assets/flags/bs.svg'
import ea from '../assets/flags/ea.svg'
import gq from '../assets/flags/gq.svg'
import kp from '../assets/flags/kp.svg'
import mv from '../assets/flags/mv.svg'
import ro from '../assets/flags/ro.svg'
import tm from '../assets/flags/tm.svg'
import bt from '../assets/flags/bt.svg'
import ec from '../assets/flags/ec.svg'
import gr from '../assets/flags/gr.svg'
import kr from '../assets/flags/kr.svg'
import mw from '../assets/flags/mw.svg'
import rs from '../assets/flags/rs.svg'
import tn from '../assets/flags/tn.svg'
import esct from '../assets/flags/es-ct.svg'
import esga from '../assets/flags/es-ga.svg'
import cefta from '../assets/flags/cefta.svg'
import gbeng from '../assets/flags/gb-eng.svg'
import gbnir from '../assets/flags/gb-nir.svg'
import gbsct from '../assets/flags/gb-sct.svg'
import gbwls from '../assets/flags/gb-wls.svg'
import espv from '../assets/flags/es-pv.svg'


function Flags() {

  const Gallery = () => {
    var settings = {
      autoplay: true,
      arrows: false,
      infinite: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      speed: 1000,
      dots: false,
      slidesToShow: 24,
      slidesToScroll: 12,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <>
        <Slider {...settings}>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ac} alt={'ac'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bv} alt={'bv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ee} alt={'ee'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gs} alt={'gs'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kw} alt={'kw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mx} alt={'mx'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ru} alt={'ru'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={to} alt={'to'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ad} alt={'ad'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bw} alt={'bw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={eg} alt={'eg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gt} alt={'gt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ky} alt={'ky'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={my} alt={'my'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={rw} alt={'rw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tr} alt={'tr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ae} alt={'ae'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={by} alt={'by'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={eh} alt={'eh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gu} alt={'gu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kz} alt={'kz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mz} alt={'mz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sa} alt={'sa'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tt} alt={'tt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={af} alt={'af'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bz} alt={'bz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={er} alt={'er'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gw} alt={'gw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={la} alt={'la'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={na} alt={'na'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sb} alt={'sb'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tv} alt={'tv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ag} alt={'ag'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ca} alt={'ca'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gy} alt={'gy'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lb} alt={'lb'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nc} alt={'nc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sc} alt={'sc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tw} alt={'tw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ai} alt={'ai'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cc} alt={'cc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={hk} alt={'hk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lc} alt={'lc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ne} alt={'ne'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sd} alt={'sd'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tz} alt={'tz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={al} alt={'al'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cd} alt={'cd'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={hm} alt={'hm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={li} alt={'li'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nf} alt={'nf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={se} alt={'se'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ua} alt={'ua'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={am} alt={'am'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={es} alt={'es'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={hn} alt={'hn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lk} alt={'lk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ng} alt={'ng'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sg} alt={'sg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ug} alt={'ug'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ao} alt={'ao'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cf} alt={'cf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={et} alt={'et'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={hr} alt={'hr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lr} alt={'lr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ni} alt={'ni'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sh} alt={'sh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={um} alt={'um'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={aq} alt={'aq'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cg} alt={'cg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={eu} alt={'eu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ht} alt={'ht'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ls} alt={'ls'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nl} alt={'nl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={si} alt={'si'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={un} alt={'un'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ar} alt={'ar'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ch} alt={'ch'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fi} alt={'fi'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={hu} alt={'hu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lt} alt={'lt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={no} alt={'no'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sj} alt={'sj'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={us} alt={'us'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={as} alt={'as'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ci} alt={'ci'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fj} alt={'fj'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ic} alt={'ic'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lu} alt={'lu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={np} alt={'np'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sk} alt={'sk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={uy} alt={'uy'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={at} alt={'at'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ck} alt={'ck'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fk} alt={'fk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={id} alt={'id'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={lv} alt={'lv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nr} alt={'nr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sl} alt={'sl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={uz} alt={'uz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={au} alt={'au'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cl} alt={'cl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fm} alt={'fm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ie} alt={'ie'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ly} alt={'ly'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nu} alt={'nu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sm} alt={'sm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={va} alt={'va'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={aw} alt={'aw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cm} alt={'cm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fo} alt={'fo'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={il} alt={'il'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ma} alt={'ma'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={nz} alt={'nz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sn} alt={'sn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={vc} alt={'vc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ax} alt={'ax'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cn} alt={'cn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={fr} alt={'fr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={im} alt={'im'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mc} alt={'mc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={om} alt={'om'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={so} alt={'so'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ve} alt={'ve'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={az} alt={'az'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={co} alt={'co'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ga} alt={'ga'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={iin} alt={'in'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={md} alt={'md'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pa} alt={'pa'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sr} alt={'sr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={vg} alt={'vg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ba} alt={'ba'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cp} alt={'cp'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={io} alt={'io'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={me} alt={'me'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pe} alt={'pe'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ss} alt={'ss'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={vi} alt={'vi'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bb} alt={'bb'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cr} alt={'cr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={iq} alt={'iq'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mf} alt={'mf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pf} alt={'pf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={st} alt={'st'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={vn} alt={'vn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bd} alt={'bd'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cu} alt={'cu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ir} alt={'ir'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mg} alt={'mg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pg} alt={'pg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sv} alt={'sv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={vu} alt={'vu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={be} alt={'be'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cv} alt={'cv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={is} alt={'is'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mh} alt={'mh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ph} alt={'ph'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sx} alt={'sx'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={wf} alt={'wf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bf} alt={'bf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cw} alt={'cw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gb} alt={'gb'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={it} alt={'it'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mk} alt={'mk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pk} alt={'pk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sy} alt={'sy'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ws} alt={'ws'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bg} alt={'bg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cx} alt={'cx'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gd} alt={'gd'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={je} alt={'je'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ml} alt={'ml'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pl} alt={'pl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={sz} alt={'sz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={xk} alt={'xk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bh} alt={'bh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cy} alt={'cy'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ge} alt={'ge'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={jm} alt={'jm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mm} alt={'mm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pm} alt={'pm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ta} alt={'ta'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={xx} alt={'xx'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bi} alt={'bi'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cz} alt={'cz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gf} alt={'gf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={jo} alt={'jo'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mn} alt={'mn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pn} alt={'pn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tc} alt={'tc'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ye} alt={'ye'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bj} alt={'bj'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={de} alt={'de'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gg} alt={'gg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={jp} alt={'jp'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mo} alt={'mo'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pr} alt={'pr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={td} alt={'td'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={yt} alt={'yt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bl} alt={'bl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={dg} alt={'dg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gh} alt={'gh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ke} alt={'ke'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mp} alt={'mp'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ps} alt={'ps'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tf} alt={'tf'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={za} alt={'za'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bm} alt={'bm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={dj} alt={'dj'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gi} alt={'gi'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kg} alt={'kg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mq} alt={'mq'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pt} alt={'pt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tg} alt={'tg'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={zm} alt={'zm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bn} alt={'bn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={dk} alt={'dk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gl} alt={'gl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kh} alt={'kh'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mr} alt={'mr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={pw} alt={'pw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={th} alt={'th'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={zw} alt={'zw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bo} alt={'bo'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={dm} alt={'dm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gm} alt={'gm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ki} alt={'ki'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ms} alt={'ms'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={py} alt={'py'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tj} alt={'tj'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bq} alt={'bq'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ddo} alt={'do'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gn} alt={'gn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={km} alt={'km'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mt} alt={'mt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={qa} alt={'qa'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tk} alt={'tk'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={br} alt={'br'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={dz} alt={'dz'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gp} alt={'gp'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kn} alt={'kn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mu} alt={'mu'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={re} alt={'re'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tl} alt={'tl'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bs} alt={'bs'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ea} alt={'ea'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gq} alt={'gq'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kp} alt={'kp'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mv} alt={'mv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ro} alt={'ro'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tm} alt={'tm'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={bt} alt={'bt'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={ec} alt={'ec'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gr} alt={'gr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={kr} alt={'kr'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={mw} alt={'mw'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={rs} alt={'rs'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={tn} alt={'tn'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={esct} alt={'es-ct'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={esga} alt={'es-ga'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={espv} alt={'es-pv'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={cefta} alt={'cefta'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gbeng} alt={'gb-eng'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gbnir} alt={'gb-nir'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gbsct} alt={'gb-sct'} /></AnchorLink></div>
          <div className="px-1 pt-1"><AnchorLink href="flag_block"><img src={gbwls} alt={'gb-wls'} /></AnchorLink></div>
        </Slider>
      </>
    );
  }

  return (
    <div id="flag_block" style={{ backgroundColor: 'white' }}>
      <div className="subtitle text-right w-90 ma h-10 c-theme pt-4">Available in all languages</div>
      <Gallery />
    </div>
  );

}

export default Flags
