import React from 'react'

export default function Intro() {

  return (
    <div className="intro">
      <main>
        <div className="flex jcc w-100 rowwrap h-100">
          <div className="w-50 ma">
            <div className="s-100"></div>
            <div className="s-60"></div>
            {/*<div>
              <p className="intro__subtitle text-left c-theme">A Content platform that is guaranteed to make you smile!
              </p>
              <h2 className="intro__title font-bold  text-left">
                 Currently, we are mixing potions in our lab. Stay Tuned!
              </h2>
            </div>*/}
            <div>
              <p className="intro__subtitle text-left c-theme">Use promotional text for instant success!
              </p>
              <h2 className="intro__title font-bold  text-left">
                 Let me create the juicy text and hook your customers!
              </h2>
            </div>
          </div>
          <div className="w-50 ma">
            <img src="" alt="" />
          </div>
        </div>
      </main>
    </div>
  )
}
