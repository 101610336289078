import React from 'react'



function Info() {
  return (
    <div id="info_block">

      <div className="flex w-100 h-100 jcsa aic">
        <div className="w-40 center p-5 h-50 bg-theme">
          <h3 className="subtitle c-white mt-3 mx-4"><strong>Our Vision</strong></h3>
          <p className="text c-white px-3 mx-4">The world is moving towards a global economy and as a result, content is pushed to language-specific websites. However, there are many countries who are fighting for their own writers and brands to be recognized. Some of these countries have started to produce their own English articles in order to be recognized on the global stage.</p>
        </div>
        <div className="w-40 center p-5 h-50 bg-premium">
          <h3 className="subtitle c-white mt-3 mx-4"><strong>Our Mission</strong></h3>
          <p className="text c-white px-3 mx-4">In the past, copying content could be difficult and time-consuming. Writers had to find or create an article and then rewrite it in their own voice. Now, AI writing assistants are making this process easier. They can use pre-existing data as a starting point for content and then make it their own. The result is accessible content thanks to technological innovation.</p>
        </div>
      </div>
    </div>
  )
}

export default Info
