import React from 'react'
import { ImPencil2, ImHappy2, ImUsers } from "react-icons/im"
import Title from './Title'


function Features() {
  return (
    <div className="section" id="feature_block">
      <Title
        type="title c-premium"
        text="Professional content experts just a click away!"
      />
      <div className="container">
        <h2 className="subtitle w-60 ma text-center">Don't waste your time trying to write content and manage a team of writers. We do that for you, so you can focus on results.
        </h2>
        <div className="s-40"></div><div className="s-40"></div>
        <div className="flex jcsa w-90 ma">
          <div className="w-30 text-center">
            <span className="a-icon middle r-64 mb-5 bg-theme">
              <ImPencil2 size={24} color={'white'} />
            </span>
            <h3 className="subtitle c-theme">The right content at your fingertips</h3>
            <p className="text">With a large pool of experienced ghostwriters and translators, it's never been easier to find the right person for the job. Don't worry about finding and training new people, either - all our team members are vetted and have extensive experience.</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon middle r-64 mb-5 bg-theme">
              <ImHappy2 size={24} color={'white'} />
            </span>
            <h3 className="subtitle c-theme">Quality Assurance & Quality Control</h3>
            <p className="text">
              We know your business is important to you, which is why we provide the finest quality assurance and control for every writer and translator in our network.</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon middle r-64 mb-5 bg-theme">
              <ImUsers size={24} color={'white'} />
            </span>
            <h3 className="subtitle c-theme">Stay on top of deadlines - with us</h3>
            <p className="text">
              We know how important it is to stay on top of deadlines, which is why we work with you to ensure projects are delivered on time. No more guessing when your project will be done!</p>
          </div>
        </div>
        <div className="s-80"></div>
        <div className="text-center">
          <a className="btn btn--premium btn--big" href="https://www.linkedin.com/company/con10tlabs/">Start Now</a>
        </div>
      </div>
    </div>
  )
}

export default Features
